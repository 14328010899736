<template>
  <div>
    <div v-if="currentFile" style="margin-bottom: 40px;">
      <label for="description" class="el-form-item__label">{{
        currentLabel
      }}</label>
      <div>
        <el-link target="_blank" :href="currentFile">{{
          currentFileName
        }}</el-link>
      </div>
    </div>
    <label for="description" class="el-form-item__label">{{ label }}</label>
    <file-pond
      label-idle="Datei hierhin ziehen oder klicken..."
      allow-multiple="false"
      :files="chosenFile"
      max-files="1"
      style="margin-bottom: 40px;"
      allow-paste="false"
      @addfile="addFile"
    />
  </div>
</template>

<script>
// Import FilePond
import vueFilePond from "vue-filepond"

// Import styles
import "filepond/dist/filepond.min.css"

// Create FilePond component
const FilePond = vueFilePond()

export default {
  components: {
    FilePond
  },
  props: {
    label: {
      type: String,
      required: true
    },
    currentLabel: {
      type: String,
      required: true
    },
    currentFile: {
      type: String,
      default: null
    },
    currentFileName: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      chosenFile: null
    }
  },
  computed: {},
  created() {},
  methods: {
    addFile(error, data) {
      this.$emit("file-chosen", data.file)
    }
  }
}
</script>
