import store from "@/store"
import Repository from "@/repositories/api.js"
const resource = "/admin/downloads"

export default {
  async getAll(params) {
    let response = await Repository.get(resource, {
      params: { ...params, ...{ region_id: store.state.auth.chosenRegion } }
    })
    return response.data
  },
  async get(downloadID) {
    let response = await Repository.get(`${resource}/${downloadID}`)
    return response.data.download
  },
  async create(downloadData) {
    const formData = new FormData()
    const entries = Object.entries(downloadData)
    for (const [key, value] of entries) {
      formData.append(`download[${key}]`, value ? value : "")
    }
    formData.append("region_id", store.state.auth.chosenRegion)
    let response = await Repository.post(`${resource}`, formData)
    return response.data
  },
  async update(downloadID, downloadData) {
    const formData = new FormData()
    const entries = Object.entries(downloadData)
    for (const [key, value] of entries) {
      formData.append(`download[${key}]`, value ? value : "")
    }
    let response = await Repository.patch(`${resource}/${downloadID}`, formData)
    return response.data
  },
  async destroy(downloadID) {
    let response = await Repository.delete(`${resource}/${downloadID}`)
    return response.data
  }
}
