<template>
  <base-form
    v-slot="slotProps"
    :model="download"
    :rules="rules"
    :loading="loading"
    :form-submitted="formSubmitted"
    :submit-label="submitLabel"
    :mode="mode"
    :additional-validators="[
      {
        validator: checkFile,
        onError: missingFileWarning
      }
    ]"
    model-name="Download"
  >
    <el-form-item label="Name" prop="name">
      <el-input v-model="slotProps.model.name" v-focus></el-input>
    </el-form-item>

    <el-form-item label="Kategorie" prop="download_category_id">
      <el-select
        v-model="slotProps.model.download_category_id"
        v-loading="loadingCategories"
        filterable
        placeholder="Bitte auswählen"
      >
        <el-option
          v-for="item in categories"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </el-form-item>

    <el-form-item label="Beschreibung" prop="description">
      <el-input
        v-model="slotProps.model.description"
        type="textarea"
        :rows="3"
      ></el-input>
    </el-form-item>

    <el-radio-group v-model="downloadType" style="margin-bottom: 16px;">
      <el-radio label="file" border>Eigene Datei hochladen</el-radio>
      <el-radio label="url" border>URL eingeben</el-radio>
    </el-radio-group>

    <file-upload-field
      v-if="downloadType === 'file'"
      current-label="Aktuelle Datei"
      label="Datei auswählen"
      :current-file="slotProps.model.uploaded_file_url"
      :current-file-name="slotProps.model.file_name"
      @file-chosen="setFile"
    />

    <el-form-item v-else label="URL der Datei" prop="file_url">
      <el-input v-model="slotProps.model.file_url" v-focus></el-input>
    </el-form-item>
  </base-form>
</template>

<script>
import ApiErrorHandlerMixin from "@/mixins/api_error_handler.js"
import DownloadCategoriesRepository from "@/repositories/download_categories_repository.js"
import BaseForm from "@/components/forms/BaseForm"
import FileUploadField from "@/components/FileUploadField"

export default {
  components: {
    BaseForm,
    FileUploadField
  },
  mixins: [ApiErrorHandlerMixin],
  props: {
    download: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    submitLabel: {
      type: String,
      default: "Änderungen speichern"
    },
    formSubmitted: {
      type: Function,
      required: true
    },
    mode: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loadingCategories: true,
      downloadType: "file",
      categories: []
    }
  },
  computed: {
    persisted() {
      return this.download.hasOwnProperty("id")
    },
    rules() {
      return {
        name: [
          { required: true, message: "Bitte Namen angeben", trigger: "blur" }
        ],
        file_url: [
          {
            required: this.downloadType === "url",
            message: "Bitte URL eingeben",
            trigger: "blur"
          },
          {
            type: "url",
            message: "Bitte gültige URL mit https:// oder http:// angeben",
            trigger: "blur"
          }
        ]
      }
    }
  },
  watch: {
    download: function(newVal) {
      if (this.mode === "edit") {
        this.downloadType = newVal.has_url ? "url" : "file"
      }
    }
  },
  created() {
    DownloadCategoriesRepository.getAll()
      .then(data => {
        this.categories = data
        this.loadingCategories = false
      })
      .catch(error => {
        this.loadingCategories = false
        this.handleApiError(error, "Fehler Laden Downloadkategorien")
      })
  },
  methods: {
    setFile(file) {
      this.download.file = file
    },
    checkFile() {
      if (this.downloadType === "file") {
        this.download.file_url = null

        if (this.download.file || this.download.uploaded_file_url) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    },
    missingFileWarning() {
      this.$message({
        message: `Sie müssen eine Datei auswählen`,
        type: "error",
        showClose: true
      })
    }
  }
}
</script>
